import { useProduct, useProductVariantGroups } from "@wow-star/utils";
import { Logger } from "aws-amplify";
import { useMemo, useRef } from "react";
import { connect } from "react-redux";
import Image from "~/components/image";

import ALink from "~/components/features/custom-link";
import Quantity from "~/components/features/quantity";
import { Star } from "~/components/icons";
import { PRODUCT_TAG_LIST } from "~/constant";
import { cartActions } from "~/store/cart";
import { modalActions } from "~/store/modal";
import { getCartCount, toDecimal } from "~/utils";
import { getRecordKey, getUpdatedCart } from "~/utils/helper";
import { useRouter } from "next/router";

const logger = new Logger("Product-details");

function ProductTwo(props) {
  const {
    setCartVisibility,
    cartList,
    product,
    adClass = "text-center",
    addToCart,
    openQuickview,
    updateCart,
    removeFromCart,
    slug: tagSlug,
    section,
    priority,
    isSearch,
  } = props;

  const [selectedVariant] = useProductVariantGroups(product);
  const productsNew = useProduct(product, selectedVariant?.id);
  const router = useRouter();
  const isHomePage = router.asPath === "/";

  const {
    title,
    slug,
    rating,
    totalRatings,
    collections,
    price,
    listingPrice,
    hasInventory,
    currentInventory,
    thumbImage,
    discount,
  } = productsNew || {};

  const previousCartList = useRef(cartList);

  // const { price, listingPrice } = useProductPrice(product);

  const showQuickviewHandler = () => {
    openQuickview(slug);
    logger.verbose("Opened quick view for product:", slug);
  };

  // const {  } = useMemo(
  //   () => getProductInventory(product),
  //   [product]
  // );

  let selectedLabel = product?.collectionsList?.length
    ? product?.collectionsList?.find((col) => !!col.label)
    : "";

  product?.collectionsList?.map((item) => {
    if (!!item?.label?.trim() && item?.priority > selectedLabel?.priority) {
      selectedLabel = item;
    }
  });

  const tag = useMemo(() => {
    if (PRODUCT_TAG_LIST.includes(tagSlug)) {
      return tagSlug.replace("-", " ").toUpperCase();
    }
    if (!collections) return;
    const [result] = collections.filter((c) => PRODUCT_TAG_LIST.includes(c));
    if (result) {
      return result.replace("-", " ").toUpperCase();
    }
    return;
  }, [collections]);

  const showCartVisibility = ({ showEveryTime = false }) => {
    if (cartList || previousCartList.current !== cartList) {
      previousCartList.current = cartList;

      const totalCartItems =
        getCartCount(cartList) + (productsNew?.minimumOrderQuantity || 1);

      if (tagSlug === "bundle-offer") {
        const showCartModal =
          totalCartItems > 0 && totalCartItems % 5 !== 0 ? false : true;
        showCartModal && setCartVisibility(true);
      } else if (tagSlug === "buy-3-599") {
        const showCartModal =
          totalCartItems > 0 && totalCartItems % 3 !== 0 ? false : true;
        showCartModal && setCartVisibility(true);
      } else if (tagSlug === "special-bundle-offer") {
        const showCartModal =
          totalCartItems > 0 && totalCartItems % 8 !== 0 ? false : true;
        showCartModal && setCartVisibility(true);
      } else if (isHomePage || tagSlug === "all-products") {
        const showCartModal =
          totalCartItems > 0 && totalCartItems % 5 !== 0 ? false : true;
        showCartModal && setCartVisibility(true);
      }
      // else if (tagSlug === "all-products" || isHomePage) {
      //   const showCartModal =
      //     totalCartItems > 0 && totalCartItems % 2 !== 0 ? false : true;
      //   showCartModal && setCartVisibility(true);
      // }
      else {
        showEveryTime && setCartVisibility(true);
      }
    }
  };

  const addToCartHandler = (e) => {
    e?.preventDefault();

    addToCart({
      ...productsNew,
      section,
      qty: productsNew?.minimumOrderQuantity || 1,
    });
    showCartVisibility({ showEveryTime: true });
    logger.verbose("Added product to cart");
    logger.debug("Added product to cart:", product);

    return false;
  };

  const cartItem = useMemo(() => {
    const recordKey = getRecordKey(productsNew);
    return cartList.find((cl) => cl.recordKey === recordKey);
  }, [cartList]);

  // const { thumbImage, discount } = getProductMeta(product);
  const subtitle = productsNew?.additionalInfo?.find(
    (info) => info.label === "subtitle"
  );

  function changeQty(qty) {
    if (cartItem) {
      if (qty) {
        const recordKey = getRecordKey(productsNew);
        const cartData = getUpdatedCart(cartList, recordKey, { qty });
        updateCart(cartData);

        showCartVisibility({ showEveryTime: false });
        logger.verbose("Updated product quantity in cart");
        logger.debug(
          "Updated product quantity in cart:",
          product,
          "New quantity:",
          qty
        );
      } else {
        removeFromCart({ ...cartItem });
        logger.verbose("Removed product from cart");
        logger.debug("Removed product from cart:", cartItem);
      }
    }
  }
  // const imageKey = isSearch ? product.imageUrl : thumbImage?.imageKey;
  const productLabel = selectedLabel?.label?.trim() || tag;

  return (
    <div className={`product text-left ${adClass} product-card`}>
      {/* <figure className="product-media"> */}
      <span className="product-image product-shadow">
        {!!thumbImage?.imageKey && (
          <ALink href={`/products/${slug}`}>
            <Image
              src={thumbImage?.imageKey}
              alt={title}
              height={450}
              width={450}
              quality={95}
              priority={!!priority}
            />{" "}
          </ALink>
        )}
      </span>
      <div className="product-label-group">
        {discount > 0 && (
          <label className="product-label label-sale">-{discount}%</label>
        )}
      </div>

      <div className="product-label-group">
        {discount > 0 && (
          // (product.variants?.items?.length < 2 ? (
          <label className="product-label label-sale">-{discount}%</label>
        )}
      </div>

      {/* {!!tag && (
        <div className="product-tags-group">
          <label className="product-label label-tag">{tag}</label>
        </div>
      )} */}
      {!!productLabel && (
        <div className="product-tags-group">
          <label className="product-label label-tag">{productLabel}</label>
        </div>
      )}
      {/* {!!label && (
        <div className="label-tag-group">
          <label className="product-label label-tag">
            {label.toUpperCase()}
          </label>
        </div>
      )} */}

      <div className="product-details card pt-1">
        <ALink href={`/products/${slug}`}>
          <div className="details-wrapper">
            <h3 className="product-name text-uppercase product-card-title p-0 font-weight-semi-bold">
              {" "}
              {title}
            </h3>
            {/* <div className="product-tags lh-default">
            {product?.tags?.split(",").join(" | ") || <>&nbsp;</>}
          </div> */}
            {subtitle?.value && (
              <div
                className="product-benefits"
                dangerouslySetInnerHTML={{
                  __html: subtitle?.value,
                }}
              ></div>
            )}
          </div>
          <div className="product-price product-sm mb-2 lh-1">
            <ins className="new-price ">₹{toDecimal(price || 0)}</ins>
            {price < listingPrice && listingPrice && (
              <span className="old-price ml-1 ">
                <del>₹{toDecimal(listingPrice || 0)}</del>
              </span>
            )}
          </div>
          {totalRatings > 0 ? (
            <div className="ratings-container">
              <div className="ratings-full d-flex rating-product-list mr-1">
                <Star size={20} color={"#fab73b"} />
              </div>
              <span className="rating text-black font-weight-bold">
                {rating}
              </span>
              <ALink
                href={{
                  pathname: `/products/${slug}`,
                  query: { review: true },
                }}
                className="rating-reviews text-black font-weight-bold"
              >
                ({totalRatings || 0} reviews)
              </ALink>
            </div>
          ) : (
            <div className="margin-ratings"> {}&nbsp;</div>
          )}
        </ALink>
        <div className="product-action pl-2 pr-2">
          {!!hasInventory ? (
            <>
              {!!cartItem ? (
                <Quantity
                  isProductList={true}
                  qty={cartItem.qty}
                  max={currentInventory}
                  product={productsNew}
                  minimumOrderQuantity={productsNew?.minimumOrderQuantity || 1}
                  maximumOrderQuantity={productsNew?.maximumOrderQuantity || 99}
                  onChangeQty={changeQty}
                />
              ) : (
                <a
                  href="#"
                  className={`btn-product btn-primary btn-quickview m-0 ${
                    price <= 0 ? "disabled" : ""
                  }`}
                  title="Add to cart"
                  onClick={addToCartHandler}
                  style={{ backgroundColor: price <= 0 ? "#ccc" : "" }}
                >
                  Add to cart
                </a>
              )}
            </>
          ) : (
            <a
              href="#"
              className="btn-product btn-sold-out m-0"
              title="Sold Out"
            >
              Sold Out
            </a>
          )}
        </div>
      </div>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    cartList: state.cart.data || [],
  };
}

export default connect(mapStateToProps, {
  addToCart: cartActions.addToCart,
  updateCart: cartActions.updateCart,
  removeFromCart: cartActions.removeFromCart,
  ...modalActions,
})(ProductTwo);
